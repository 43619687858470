<template>
  <div :class="['shares-card', { open: isOpen }]" @click="show()">
    <div class="content">
      <div class="info">
        <div class="title">
          {{ title }}
        </div>
        <div class="description" v-if="haveDescription">
          <div class="total-shares">Паев во владении: {{ totalCount }}</div>
          <div class="current-price">Текущая цена пая: {{ actualPrice.toLocaleString() }} ₽</div>
          <div class="total-price">Общая стоимость: {{ totalCost.toLocaleString() }} ₽</div>
        </div>
      </div>
      <div class="icon">
        <v-icon size="xs" @click="show()"
          >{{ !isOpen ? "caret-down" : "caret-up" }}
        </v-icon>
      </div>
    </div>
    <transition name="slide" mode="in-out">
      <div v-if="isOpen">
        <div v-if="shares || purchases || sales || isFirstRedemption" class="info-content">
<!--          for owned shares page-->
          <shares-info
            v-for="card in shares"
            :key="`${card.id}-info-card`"
            :price="card.share_price"
            :count="card.share_count"
            :files="card.files"
            @download="download"
          />
<!--          requests list for both types purchase & redemption -->
          <template v-for="card in list">
            <shares-info
              :scan="card.scan"
              :key="`${card.id}-info-card`"
              :title="card.name"
              :signDate="card.date_sign"
              :status="card.status"
              :status-id="card.status_id"
              :document="[]"
              :attachment="card.files"
              :comment="card.type_id === 9 || card.type_id === 10 ? card.comment_reject : null"
              @download="toDownload(card)"
              @download-file="downloadFile"
            />
          </template>
          <div
            class="row fund-request_body_container_buy-purchases"
            v-if="redemption && active"
          >
            <div class="col-3">
              <rwm-button width="100%" @click="$emit('redemptionRequest', id)"
                >Заявка на погашение</rwm-button
              >
            </div>
          </div>
          <div
            class="row fund-request_body_container_buy-purchases"
            v-else-if="isHasPurchases && ((ifQualifiedInvestor && ifFundQualInvestor) || !ifFundQualInvestor)"
          >
            <div class="col-3">
              <rwm-button width="100%" @click="$emit('buy')">Купить паи</rwm-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VIcon from "../icons/VIcon";
import SharesInfo from "./SharesInfo";
import InfoModal from "../modals/InfoModal";
import RwmButton from "../RWMButton/RwmButton";
export default {
  name: "RwmSharesCard",
  components: { RwmButton, SharesInfo, VIcon },
  props: {
    title: String,
    totalCount: [String, Number],
    totalPrice: [String, Number],
    totalCost: [String, Number],
    actualPrice: [String, Number],
    fundStatus: [String, Number],
    id: [String, Number],
    shares: Array,
    sales: Array,
    purchases: Array,
    redemption: Boolean,
    active: Boolean,
    specReg: String,
    ifQualifiedInvestor: Boolean,
    ifFundQualInvestor: Boolean,
  },
  computed: {
    isOpenCard() {
      return this.isOpen && this.shares && this.shares.length > 0;
    },
    haveDescription() {
      return !!this.totalCount || !!this.totalCost || !!this.totalPrice;
    },
    isFirstRedemption() {
      return (
          !(this.fundStatus === 6 || this.fundStatus === 3) && this.redemption
      );
    },
    isHasPurchases() {
      return (
        (this.fundStatus === 6 || this.fundStatus === 3) && !this.redemption
      );
    },
    list() {
      return this.redemption ? this.sales : this.purchases;
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    routingTo(type, id) {
      let rout = type === 4 ? "/cabinet/documents/forms/registered-person" : type === 5 ? "/cabinet/documents/forms/open-personal-account" : "/cabinet/investment"
      this.$router.push({path: rout, query: {to: `${id}`}})
    },
    show() {
      this.purchases?.length > 0 ||
      this.shares?.length > 0 ||
      this.sales?.length > 0 ||
      this.isFirstRedemption
        ? (this.isOpen = !this.isOpen)
        : false;
    },
    toDownload(card) {
        this.download({ id: card.id, name: card.name, type: 'pdf' })
    },
    download(file) {
      this.$emit("download", file);
    },
    downloadFile(file) {
      this.$emit("download-file", file);
    },
    buy() {
      if (this.isHasPurchases) {
        this.$router.push(
          `/cabinet/investment/purchase-of-investment-shares/${this.id}`
        );
      } else {
        this.$modal.show(
          InfoModal,
          {
            title: this.title,
            text: "Уважаемый клиент!<br>" +
                "К сожалению, на текущий момент фонд не производит выдачу инвестиционных паев, мы обязательно сообщим Вам о факте начала дополнительного выпуска.",
            icon: "Warning",
          },
          {
            width: 522,
            height: "auto",
            clickToClose: false,
            scrollable: true,
            name: "InfoModal",
          },
          {
            "before-open": this.beforeOpen,
            "before-close": this.beforeClose,
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.shares-card {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 24px 24px 22px 24px;

  border: 1px solid #c1cbd6;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: #f7f9fa;
    & .icon {
      color: $RWM-red;
    }
  }

  &.open {
    background-color: #f7f9fa;
  }

  & .content {
    display: flex;
    flex: 1 1 auto;
    & .info {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      row-gap: 24px;
      @media screen and (min-width: 780px) {
        row-gap: 12px;
      }
      @media screen and (min-width: 960px) {
        row-gap: 24px;
      }
      @media screen and (min-width: 1240px) {
        row-gap: 12px;
      }
      & .title {
        display: flex;
        flex: 1 1 auto;
        align-items: center;

        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        /* Grayscale/800 */
        color: #333f4f;
      }
      & .description {
        display: flex;
        column-gap: 34px;
        row-gap: 24px;
        flex-direction: column;

        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;

        /* Grayscale/700 */
        color: #586770;
        @media screen and (min-width: 780px) {
          flex-direction: row;
        }
        @media screen and (min-width: 960px) {
          flex-direction: column;
        }
        @media screen and (min-width: 1240px) {
          flex-direction: row;
        }

        & div {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: -17px;
            width: 1px;
            background-color: $RWM-grey-super-light;
            display: none;
            @media screen and (min-width: 780px) {
              display: block;
            }
            @media screen and (min-width: 960px) {
              display: none;
            }
            @media screen and (min-width: 1240px) {
              display: block;
            }
          }
          &:last-child:after {
            display: none;
          }
        }
      }
    }
    & .icon {
      display: flex;
      align-items: flex-start;
      @media screen and (min-width: 780px) {
        align-items: center;
      }
      @media screen and (min-width: 960px) {
        align-items: flex-start;
      }
      @media screen and (min-width: 1240px) {
        align-items: center;
      }
    }
  }

  & .info-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    row-gap: 24px;
    column-gap: 24px;
    padding-top: 24px;
    &.in-work {
      display: block;
      background-color: $RWM-white;
      padding: 24px;
      margin-top: 24px;
      margin: 24px -24px -24px;
      & h4 {
        margin: 0 0 8px 0;
        font-size: 17px;
        line-height: 22px;
      }
      & p {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
      }
      & .anket-info {
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #C1CBD6;
        @media screen and (min-width: 1120px) {
          flex-direction: row;
          column-gap: 18px;
        }
        &:last-child {
          border-bottom: none;
        }
        & p {
          margin: 0;
          &.green {
            color: $RWM-green;
          }
          &.red {
            color: $RWM-red;
          }
        }
        & .anket {
          &-name,
          &-status,
          &-comment,
          &-btn {
            width: 100%;
            &.hide {
              display: none;
              @media screen and (min-width: 1120px) {
                display: block;
              }
            }
          }
          @media screen and (min-width: 1120px) {
            &-name {
              width: 30%;
            }
            &-status {
              width: 150px;
            }
            &-comment {
              width: auto;
              flex: 1 1 auto;
            }
            &-btn {
              width: 240px;
            }
          }
        }
      }
    }
  }
  & .fund-request_body_container_buy-purchases {
    justify-content: center;
  }
}
</style>
