<template>
  <div :class="['info-card', {archive : statusId === 9}]">
    <div class="card-row" v-if="title">
      <p class="title">{{ title }}</p>
      <v-icon size="large" v-if="document" @click="toDownload(scan)"
        >{{ scan ? "note" : "note-blank" }}</v-icon
      >
    </div>
    <template v-if="price || count || (files && files.length > 0)">
      <div class="card-row">
        <p>Цена приобретения</p>
        <p>{{ price.toLocaleString() }} ₽</p>
      </div>
      <div class="card-row">
        <p>Количество паев</p>
        <p>{{ count }}</p>
      </div>
      <div class="card-row">
        <p>Увeдомление о покупке</p>
        <v-icon
          size="large"
          v-if="files && files.length > 0"
          @click="
            $emit('download', { id: files[0].id, name: files[0].origin_name })
          "
          >
          {{files[0].origin_name.split(".")[1].match("pdf") ? "note-blank" : "note"}}
        </v-icon
        >
      </div>
    </template>
    <template v-if="document">
      <div class="card-row">
        <p>Дата подписания</p>
        <p>{{ getSignDate }}</p>
      </div>
      <div class="card-row" v-if="status">
        <p>Статус</p>
        <p :class="[{red : +statusId===4}]">{{ status }}</p>
      </div>
      <div class="card-row" v-if="specReg">
        <p>СпецРег</p>
        <p>{{ specReg }}</p>
      </div>
      <div class="card-row" v-if="event">
        <p>Событие</p>
        <p>{{ event }}</p>
      </div>
      <div class="card-row" v-if="comment">
        <p>Комментарий</p>
        <p class="comment">{{ comment }}</p>
      </div>
      <template v-if="attachment">
        <div class="card-row" v-for="file in attachment" :key="`${file.id}-attachment`">
          <p>{{getTitle(file.file_type)}}</p>
          <v-icon
                  size="large"
                  v-if="attachment && attachment.length > 0"
                  @click="
            $emit('download-file', {
              id: file.id,
              name: file.origin_name,
            })
          "
          >{{getIcon(file.file_type)}}</v-icon
          >
        </div>
      </template>
    </template>
    <!--      Анкета зарегистрированного физического лица-->
    <!--      Дата подписания (устанавливается автоматически при отправке документа)-->
    <!--      Статус (один из вариантов, логика описана в разделе Статусы документов)-->
    <!--      СпецРег (указывается соответствующий Фонду СпецРег)-->
    <!--      Событие (логика изменений событий описана в разделе 5.4.2)-->
    <!--      Вложение (подписанная анкета в формате pdf, возможность скачать)-->
  </div>
</template>

<script>
import VIcon from "../icons/VIcon";
import moment from "moment";

export default {
  name: "SharesInfo",
  components: { VIcon },
  props: {
    files: Array,
    price: [String, Number],
    count: [String, Number],
    title: String,
    signDate: [String, Number],
    status: String,
    statusId: [String, Number],
    specReg: [String, Number],
    event: String,
    attachment: Array,
    document: Array,
    comment: String,
    scan: [Object, null]
  },
  computed: {
    getSignDate() {
      return moment(this.signDate, "X", true).format("DD.MM.YYYY");
    },
  },
  methods: {
    toDownload(card) {
      if (card) {
        const name = card.text_name ? card.text_name + card.origin_name.split(".")[1] : card.origin_name
        this.$emit('download-file', {name: name, id: card.id})
      } else {
        this.$emit('download')
      }
    },
    getTitle(doc) {
      switch(doc) {
        case 303:
          return "Документы для оплаты"
        case 304:
          return "Уведомление"
      }
    },
    getIcon(doc) {
      switch(doc) {
        case 303:
          return "zip"
        case 304:
          return "note-blank"
      }
    }
  }
};
</script>

<style scoped lang="scss">
.info-card {
  display: flex;
  padding: 39px 24px;
  background: #ffffff;
  border: 1px solid #e5eaee;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  row-gap: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
  color: #586475;
  flex-direction: column;

  & .card-row {
    display: flex;
    column-gap: 12px;
    overflow: hidden;
    align-items: flex-start;
    justify-content: space-between;

    & .title {
      font-weight: bold;
      font-size: 23px;
      line-height: 31px;
      color: #586475;
    }
    & p {
      &:last-child {
        text-align: right;
      }
      &.comment {
        color: $RWM-red;
      }
    }
    & i {
      margin-left: 15px;
    }
  }
  .red {
    color: $RWM-red;
  }
  @media screen and (min-width: 1200px) {
    width: calc(50% - 16px);
  }

  &.archive {
    color: $RWM-grey-super-light;
    & .title {
      color: $RWM-grey-super-light;
    }
  }
}
</style>
